import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaSnowflake, FaGlobe, FaWifi, FaWheelchair, FaDog, FaParking, FaUtensils, FaConciergeBell, FaTaxi, FaStar } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox'; // Importa React Image Lightbox
import 'react-image-lightbox/style.css'; // Importa gli stili della lightbox
// import defaultImage from '../images/default-image.jpg';



const Details = () => {
  const [struttura, setStruttura] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingImage, setLoadingImage] = useState(true); // Stato per il caricamento dell'immagine
  const [photoIndex, setPhotoIndex] = useState(0); // Gestisce l'indice dell'immagine selezionata
  const [isOpen, setIsOpen] = useState(false); // Stato per la lightbox (aperta o chiusa)
  const classificazioniValide = ["5 Stelle", "4 Stelle", "3 Stelle", "2 Stelle", "1 stella"];


  // const [images, setImages] = useState([]);  // Nuovo stato per le immagini
  // const getCoverImage = (fotos) => {
  //   const coverImage = fotos?.find(foto => foto.is_cover === 1);
  //   return coverImage ? `https://sist.aptbasilicata.it/${coverImage.path}` : defaultImage;
  // };

  const handleBack = () => {
    // Naviga indietro senza alterare l'URL
    window.history.go(-2);
  };


  const id = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    const fetchStruttura = async () => {
      setLoading(true);
      setError('');

      try {
        // Richiesta diretta alla struttura con ID specifico
        const response = await axios.get('https://sist.aptbasilicata.it/api/strutture', {
          params: { id: id },  // Parametro id passato direttamente
        });

        const data = response.data.structures.data[0]; // Accedi direttamente ai dati della struttura

        if (data) {
          setStruttura(data);
        } else {
          setError('Nessuna struttura trovata con questo ID.');
        }
      } catch (err) {
        console.error('Errore:', err.response ? err.response.data : err);
        setError('Si è verificato un errore nel recuperare i dettagli della struttura. Per favore, riprova.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchStruttura();
    } else {
      setError('ID struttura non fornito.');
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return <p>Caricamento...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!struttura) {
    return <p>Nessuna struttura trovata.</p>;
  }

  const galleryImages = struttura.fotos.map(foto => (
    `https://sist.aptbasilicata.it/${foto.path}`
  ));

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLoadingImage(true); // Ripristina lo stato di caricamento quando il Lightbox viene aperto
    setIsOpen(true);
  };

  // Funzione per chiudere il Lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };



  const fullAddress = `${struttura.indirizzo}${struttura.civico && struttura.civico !== '/' ? `, ${struttura.civico}`  : ''} ${struttura.cap} ${struttura.rel_comune?.COMUNE} (${struttura.rel_comune?.PROV})`;

  let denominazione = struttura.denominazione;
  // Sostituisci il simbolo & con "e" solo se la denominazione contiene "B&B"
  if (denominazione.includes('&')) {
    denominazione = denominazione.replace('&', 'e');
  }
  const fullAddressMapGoogle = `https://maps.google.it/maps?q= ${struttura.indirizzo}  ${struttura.civico}  ${struttura.rel_comune?.COMUNE} &output=embed`;


  const resetFilters = () => {
    navigate(`/search`);
    window.location.reload(); // Forza il refresh della pagina

  };

  return (

    <>
      {/* 

    <div className="container-fluid rounded image-cover"
      style={{ backgroundImage: `url(${getCoverImage(struttura.fotos)})` }}>
        </div> */}

      <div className="container-fluid pt-2">
        <div className="container mt-2 mb-4" >
          <p className='breadcrumb'>
            <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home </a> &nbsp;/&nbsp; <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}> Dove Dormire </a>&nbsp;/&nbsp;{struttura.nome_completo}
          </p>
          <div className="card-body">

            <span className='classificazione'>

              {(struttura.classificazione_alb === "Non soggetta a classificazione") ||
                (struttura.classificazione_alb !== classificazioniValide) ? (
                <> {struttura.tipologia || 'Non disponibile'} </>
              ) : (
                struttura.classificazione_alb && <>{struttura.classificazione_alb}</>
              )}


              {struttura.classificazione_alb === "5 Stelle Lusso" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                </span>
              )}


              {struttura.classificazione_alb === "5 Stelle" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                </span>
              )}


              {struttura.classificazione_alb === "4 Stelle" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                </span>
              )}


              {struttura.classificazione_alb === "3 Stelle" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' />
                </span>
              )}


              {struttura.classificazione_alb === "2 Stelle" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' />
                </span>
              )}


              {struttura.classificazione_alb === "1 Stelle" && (
                <span>
                  {struttura.classificazione_alb} <FaStar className='star' />
                </span>
              )}


            </span>
            <h3 className="home-title">{struttura.denominazione || 'Nome non disponibile'} </h3>
            <div className='flex-sm-row flex-column d-flex pb-4' >
              {/* Indirizzo (collegato a Google Maps) */}


              <p className="card-text m-0">
                <FaMapMarkerAlt className='apt-color-1 details-icon' />
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-dark semibold-text details-text"
                >
                  {fullAddress}
                </a>
              </p>


              {/* Numero di telefono (cliccabile per chiamare) */}
              <p className="card-text m-0">
                <FaPhoneAlt className='apt-color-1 details-icon' />
                {struttura.cell || struttura.tel ? (
                  <a href={`tel:${struttura.cell || struttura.tel}`}
                    className="text-decoration-none text-dark semibold-text details-text">
                    {struttura.cell || struttura.tel}
                  </a>
                ) : 'Non disponibile'}
              </p>


              {/* Email (cliccabile per inviare un'email) */}
              <p className="card-text m-0">
                <FaEnvelope className='apt-color-1 details-icon' />
                {struttura.email ? (
                  <a href={`mailto:${struttura.email}`}
                    className="text-decoration-none text-dark semibold-text details-text">
                    {struttura.email}
                  </a>
                ) : 'Non disponibile'}
              </p>


              {/* Sito web (cliccabile per visitare il sito) */}
              {struttura.sitoweb !== null && (

                <p className="card-text ">
                  <FaGlobe className='apt-color-1 details-icon' />
                  {struttura.sitoweb ? (
                    <a
                      href={struttura.sitoweb.startsWith('http') ? struttura.sitoweb : `http://${struttura.sitoweb}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark semibold-text  details-text"
                    >
                      {struttura.sitoweb}
                    </a>
                  ) : 'Non disponibile'}
                </p>
              )}



            </div>



            {/*           
          <div
            className="rounded tumb-image"
            style={{
              backgroundImage: `url(${getCoverImage(struttura.fotos)})`,
            }}
          /> */}



            <hr />

            <h4 className='extra-bold-text apt-color-1'>Ubicazione</h4>
            <p className="card-text">

              {struttura.pos_aree_mt !== null && (
                <span>
                  <span className='bold-text'>Area:</span> {struttura.pos_aree_mt}<br />
                </span>
              )}

              <span className='bold-text'>Area geografica:</span> {struttura.rel_comune?.COMUNE || 'Non disponibile'}

            </p>

            <hr />

            <h4 className='extra-bold-text apt-color-1'>Consistenza ricettiva</h4>
            <p className="card-text">
              {struttura.camere_numero !== 0 && (
                <span>
                  <span className='bold-text'>Camere:</span> {struttura.camere_numero}<br />
                </span>
              )}
              {struttura.suite_numero !== 0 && (
                <span>
                  <span className='bold-text'>Suite:</span> {struttura.suite_numero}<br />
                </span>
              )}
              {struttura.unab_numero !== 0 && (
                <span>
                  <span className='bold-text'>Unità abitative numero:</span> {struttura.unab_numero}<br />
                </span>
              )}
              {struttura.tot_posti_letto_standard !== 0 && (
                <span>
                  <span className='bold-text'>Posti letto:</span> {struttura.tot_posti_letto_standard}<br />
                </span>
              )}
              {struttura.tot_posti_letto_aggiungibili !== 0 && (
                <span>
                  <span className='bold-text'>Posti letto aggiungibili:</span> {struttura.tot_posti_letto_aggiungibili}<br />
                </span>
              )}
              {struttura.tot_bagni_camera !== 0 && (
                <span>
                  <span className='bold-text'>Bagni privati:</span> {struttura.tot_bagni_camera}<br />
                </span>
              )}
              {struttura.tot_bagni_comuni !== 0 && (
                <span>
                  <span className='bold-text'>Bagni comuni:</span> {struttura.tot_bagni_comuni}<br />
                </span>
              )}

            </p>

            <hr />

            <h4 className='extra-bold-text apt-color-1'>Apertura</h4>
            <p className="card-text">
              {struttura.annuale_stagionale && (
                <>
                  <span className='bold-text'>Apertura:</span> {struttura.annuale_stagionale} <br></br>
                </>
              )}
              {struttura.stag_dal_1 && <><span className='bold-text'> Dal:</span> {struttura.stag_dal_1}</>} {struttura.stag_al_1 && <><br /><span className='bold-text'>Al:</span> {struttura.stag_al_1}</>}
              {struttura.stag_dal_2 && <><span className='bold-text'>| Dal:</span> {struttura.stag_dal_2}</>} {struttura.stag_al_2 && <><br /><span className='bold-text'>Al:</span> {struttura.stag_al_2}</>}
              {struttura.stag_dal_3 && <><span className='bold-text'> Dal:</span> {struttura.stag_dal_3}</>} {struttura.stag_al_3 && <><br /><span className='bold-text'>Al:</span> {struttura.stag_al_3}</>}
            </p>

            <hr />

            <h4 className='extra-bold-text apt-color-1'>Attrezzature e servizi</h4>

            <p className="card-text">
              {struttura.aria_codizionata === 1 && <span><FaSnowflake className='apt-color-1' /> Aria condizionata <br></br></span>}
              {struttura.wireless === 1 && <span> <FaWifi className='apt-color-1' /> Wireless <br></br></span>}
              {struttura.acc_parz_disabili === 1 && <span><FaWheelchair className='apt-color-1' /> Accesso parziale per disabili <br></br></span>}
              {struttura.acc_animali_domestici === 1 && <span><FaDog className='apt-color-1' /> Accesso animali domestici <br></br> </span>}
              {struttura.parcheggio === 1 && <span><FaParking className='apt-color-1' /> Parcheggio <br></br></span>}
              {struttura.ristorante_interno === 1 && <span><FaUtensils className='apt-color-1' /> Ristorante interno <br></br></span>}
              {struttura.servizi_camera === 1 && <span><FaConciergeBell className='apt-color-1' /> Servizi in camera <br></br></span>}
              {struttura.trasporto_clienti === 1 && <span><FaTaxi className='apt-color-1' /> Trasporto clienti <br></br></span>}
            </p>


            <hr />

            <h4 className='extra-bold-text apt-color-1'>Prezzo</h4>
            <span className='bold-text'>Prezzo:</span> {struttura.prezzi_max || 'Non disponibile'}<br />

            <hr />

            <h4 className='extra-bold-text apt-color-1'>Altre informazioni</h4>
            <span className='bold-text'>Codice esercizio:</span> {struttura.codice_esercizio || 'Non disponibile'}<br />
            <span className='bold-text'>CIN:</span> {struttura.cin || 'Non disponibile'}<br />


            <hr />

            <h4 className='extra-bold-text apt-color-1'>Galleria fotografica</h4>

            {/* <div className="image-gallery">
            <div className="gallery-grid">
              {struttura.fotos.map((foto, index) => (
                <div key={foto.id} className="gallery-item">
                  <img 
                    src={`https://sist.aptbasilicata.it/${foto.path}`} 
                    alt={`Foto ${foto.id}`} 
                    className="gallery-thumbnail"
                    onClick={() => { setIsOpen(true); setPhotoIndex(index); }} // Apri la lightbox al clic
                  />
                </div>
              ))}
            </div>
          </div> */}

            {/* Se la lightbox è aperta, mostra l'immagine intera */}
            <div className="row">
              {struttura.fotos.map((foto, index) => (
                <div key={foto.id} className="col-6 col-md-2 mb-2 mt-2">
                  <img
                    src={`https://sist.aptbasilicata.it/${foto.path}`}
                    alt={`Foto ${foto.id}`}
                    className="gallery-thumbnail"
                    onError={(e) => {
                      e.target.onerror = null; // Previene il loop in caso di errore
                      e.target.src = 'url-di-un-immagine-di-fallback'; // Sostituisci con un'immagine di fallback
                    }}
                    onClick={() => openLightbox(index)} // Apri il Lightbox
                  />
                </div>
              ))}
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={galleryImages[photoIndex]}
                nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
                prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() => {
                  setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length);
                  setLoadingImage(true); // Ripristina il caricamento quando si cambia immagine
                }}
                onMoveNextRequest={() => {
                  setPhotoIndex((photoIndex + 1) % galleryImages.length);
                  setLoadingImage(true); // Ripristina il caricamento quando si cambia immagine
                }}
                imageCaption={loadingImage ? "Caricamento..." : ""}
                onImageLoad={() => setLoadingImage(false)} // Nascondi l'indicatore di caricamento quando l'immagine è caricata
              />
            )}

            <hr />
            <h4 className='extra-bold-text apt-color-1'>Vedi sulla mappa</h4>
            {/* <Map address={fullAddressMap} /> */}

            <iframe
              width="100%"
              height="380px"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title={struttura.denominazione}
              src={fullAddressMapGoogle}>

            </iframe>


          </div>

          <hr></hr>

          <div className='row'>
            <div className='col-6 d-flex justify-content-start'>
              <button onClick={handleBack}
                className="btn apt-bg-color-1 indietro mt-4">
                Torna Indietro
              </button>
            </div>
            <div className='col-6 d-flex justify-content-end'>
              <button onClick={resetFilters} className="btn apt-bg-color-1 indietro mt-4">
                Nuova ricerca
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;